import * as React from 'react';

import Layout from '../components/layout';
import Seo from '../components/seo';

function NoPage() {
  return (
    <Layout>
      <Seo title="" />
      <div />
    </Layout>
  );
}

export default NoPage;
