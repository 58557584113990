import * as React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

function Header({ siteTitle }) {
  return (
    <header>
      <h1 className="py-20 text-center text-7xl">
        <Link to="/">{siteTitle}</Link>
      </h1>
    </header>
  );
}

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
